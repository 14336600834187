import { observable, action, computed, makeObservable } from 'mobx';
import _ from 'lodash';
import apiActions from 'api/actions';
import defaultProfileImage from 'assets/img/shared/missing.png';

export default class ClientStore {
  constructor(root) {
    makeObservable(this, {
      data: observable,
      requests: observable,
      add: action,
      addMany: action,
      remove: action,
      all: computed,
      reset: action,
      fetchAll: action,
      fetchAllCompleted: computed,
    })

    this.root = root
  }

  data = new Map();
  requests = new Map();

  add = data => {
    const client = new Client(data, this.root);
    this.data.set(client.id, client);
  }

  addMany = datas => {
    const merge = new Map();
    datas.forEach(data => {
      const client = new Client(data, this.root);
      merge.set(client.id, client);
    })
    this.data.merge(merge);
  }

  remove = id => {
    if (id && this.data.has(id)) this.data.delete(id);
  };

  get all() {
    return Array.from(this.data.values());
  }

  find(id) {
    return this.data.get(id);
  }

  reset = () => {
    this.data.clear();
    this.requests.clear();
  }

  fetchAll() {
    if (this.requests.get('all') === undefined) {
      this.requests.set('all', 'pending')
      return apiActions
        .rawGet('my_learning_center/clients')
        .then(response => {
          this.addMany(response.data);
          this.requests.set('all', 'complete');
        })
    }
    return Promise.resolve();
  }

  get fetchAllCompleted() {
    return this.requests.get('all') === 'complete';
  }
}

export class Client {
  constructor(data, root) {
    this._root = root;
    this.id = data.id;
    this.user_id = data.user_id;
    this.name = data.name;
    this.email = data.email;
    this.creditsPurchased = data.creditsPurchased;
    this.creditsUsed = data.creditsUsed;
    this.creditsRemaining = data.creditsRemaining;
    this.children_ids = data.children_ids;
    this.potential_children_ids = data.potential_children_ids;
    this.created_at = data.created_at;
  }

  get user() {
    return this._root.UserStore.find(this.user_id);
  }

  get children() {
    return _.chain(this.children_ids)
      .map(id => this._root.UserStore.find(id))
      .compact()
      .value();
  }

  get potentialChildren() {
    return this._root.PotentialChildStore.forParent(this.id);
  }

  get pendingPotentialChildren() {
    return this._root.PotentialChildStore.pendingForParent(this.id);
  }

  get childrenWithoutUsers() {
    return this._root.PotentialChildStore.forParentWithoutUsers(this.id);
  }

  get imagePath() {
    const user = this._root.UserStore.find(this.user_id);

    return user ?
      user.imagePath :
      defaultProfileImage;
  }

  get purchases() {
    return this._root.PlanPurchaseStore.purchasesForParent(this);
  }

  get learningCenters() {
    return this.learning_center_ids.map(centerId => this._root.LearningCenters.find(centerId));
  }
}
